export const theme = {
  screens: {
    sm: "500px",

    md: "768px",

    lg: "1100px",

    xl: "1350px",

    "2xl": "1536px",
  },
  container: {
    center: true,
    padding: "2rem",
    screens: {
      "2xl": "1400px",
    },
  },
  fontFamily: {},
  extend: {
    keyframes: {
      "accordion-down": {
        from: { height: "0" },
        to: { height: "var(--radix-accordion-content-height)" },
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: "0" },
      },
    },
    animation: {
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      "spin-slow": "spin 2s linear infinite",
    },
    backdropBlur: {
      DEFAULT: "5px",
    },
    textColor: {
      light: "#ced6e2",
      "light-2": "#a7a7a7",
      "light-3": "#898c90",
      "gold-light": "#eac9b1",
      primary: "#FFFFFF",
      dark: "#020C1B",
      inputPlaceholder: "#616162",
      danger: "#ff6c6c",
    },
    backgroundColor: {
      background: "#020C1B",
      "header-background": "#091627",
      "background-radial-gradient":
        "radial-gradient(92.78% 92.78% at 27.45% 10%,#afc6d4 0%,#afc6d400 100%)",
      input: "#091627",
      "input-dark": "#050E19",
      "button-light": "#3A4552",
      "button-dark": "#050E19",
      value: {
        good: "#7adc89",
        average: "#C97E0F",
        bad: "#BF3415",
        default: "#FFFFFF",
      },
      publicForm:
        "linear-gradient(180deg, rgba(175, 198, 212, 0.00) 0%, rgba(175, 198, 212, 0.12) 100%), #020C1B",
    },
    borderColor: {
      line: "#898c90",
    },
    colors: {
      "primary-gold": "#DCA37A",
      "primary-gold-dark": "#ab770f",
      "primary-gold-dark-2": "#9c7457",
      "gondola-blue": "#1e2c3d",
      "gondola-blue-2": "#7491b8",
      "gondola-blue-3": "#455A76",
      "gondola-yellow": "#f7bd54",
      "grey-4": "#c7c8ca",
      button: {
        pill: "#050e19",
        "pill-border": "#3a4552",
        "pill-text": "#707d85",
        "pill-selected": "#afc6d4",
        "pill-selected-text": "#050e19",
        "pill-2": "#08172d",
        "pill-2-border": "#ffffff",
        "pill-2-text": "#ffffff",
        "pill-2-selected": "#afc6d4",
        "pill-2-selected-text": "#050e19",
      },
      checkbox: {
        background: "#1f2732",
        "background-checked": "#afc6d4",
        checkmark: "#050e19",
      },
      card: {
        "background-dark": "#050E19",
        "background-dark-2": "#08172d",
        "background-light": "#091627",
        "border-light": "#ffffff",
        "border-dark": "#020C1B",
        "border-gold": "#CB9872",
        "hotel-card-background":
          "linear-gradient(180deg, transparent 40%, #182434 90%)",
        "linear-gradient": {
          "primary-bright":
            "linear-gradient(93deg, #EBCAB2 0.63%, #DCA37A 99.87%), #DCA37A",
          "primary-inverted":
            "linear-gradient(90deg, rgba(220, 163, 122, 0.00) 0.26%, rgba(220, 163, 122, 0.15) 66.14%), #050E19",
          default:
            "linear-gradient(90deg, rgba(175, 198, 212, 0.15) 0.26%, rgba(175, 198, 212, 0.00) 66.14%), #050E19",
          "default-2":
            "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.12) 100%), linear-gradient(180deg, #091627 0%, #091627 100%)",
          bronze:
            "linear-gradient(90deg, rgba(235, 153, 113, 0.15) 0.26%, rgba(235, 153, 113, 0.00) 66.14%), #050E19",
          silver:
            "linear-gradient(90deg, rgba(151, 179, 196, 0.15) 4.81%, rgba(255, 255, 255, 0.00) 66.13%), #050E19",
          gold: "linear-gradient(90deg, rgba(220, 163, 122, 0.15) 0.26%, rgba(220, 163, 122, 0.00) 66.14%), #050E19",
          "gold-bright":
            "linear-gradient(90deg, rgba(220, 163, 122, 0.15) 0%, rgba(220, 163, 122, 0.08) 100%), #121214",
          titanium:
            "linear-gradient(90deg, rgba(23, 67, 101, 0.15) 0.26%, rgba(23, 67, 101, 0.00) 66.14%), #050E19",
          platinum:
            "linear-gradient(90deg, rgba(178, 178, 186, 0.15) 0.26%, rgba(38, 38, 41, 0.15) 66.14%), #050E19",
        },
      },
      "loyalty-tag": {
        default: "rgba(0, 0, 0, 0.40)",
        bronze: "linear-gradient(90deg, #EB9971 -1.16%, #BA521F 99.9%)",
        silver: "linear-gradient(89deg, #9CB8C9 0.75%, #567786 97.3%)",
        gold: "linear-gradient(88deg, #CB9872 1.06%, #5E4B3D 97.89%)",
        titanium: "linear-gradient(269deg, #174365 0.74%, #5C636A 99.21%)",
        platinum: "linear-gradient(90deg, #9F9C9D 0.44%, #6E6D6B 97.16%)",
      },
      pin: {
        future: "#DCA37A",
        past: "#7A84DC",
      },
    },
  },
};
